import React,{useRef,useEffect} from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import { gsap } from "gsap"

gsap.registerPlugin(ScrollTrigger)

function Services() {
  let container = useRef(null)

  useEffect(() => {
    const title = container.firstElementChild
    const content = container.lastElementChild

    gsap.to(container, { duration: 0, css: { visibility: "visible" } })

    let tl = new gsap.timeline({
      scrollTrigger:{
        trigger: container,
        start:"-60px 70%",
        end:"+=350px",
        scrub:2,
      }
    })
    
    tl.from(
      [title],
      {
        duration: 1,
        opacity: 0,
        y: -100,
        ease: "power3.inOut"
      },
      "start"
    )
    tl.from(
      [content.children[0], content.children[1], content.children[2], content.children[3],],
      {
        duration: 1,
        opacity: 0,
        y: -100,
        ease: "power3.inOut",
        stagger: .15,
      },
      "start"
    )
  }, []);

  const data = useStaticQuery(graphql`
    query MyServicesQuery {
      allServicesJson {
        edges {
          node {
            img {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.7
                  transformOptions: {cropFocus: CENTER}
                  placeholder: BLURRED
                  width: 500
                )
              }
            }
            id
            description
            alt
            reverse
            title
          }
        }
      }
    }
  `)

  function getServices(data){
    const servicesArray = []
    data.allServicesJson.edges.forEach((item,index) => {
         
        servicesArray.push(
          <Section key={index}>
            <ColumnOne>
              <Paragraph>
                <h3>{item.node.title}</h3>
                <p>{item.node.description}</p>
              </Paragraph>
            </ColumnOne>
            <ColumnTwo>
              <ImageContainer>
                <PurposeImg image={getImage(item.node.img)} alt={item.node.alt} />
              </ImageContainer>
            </ColumnTwo>
          </Section>
        )
    })
 return servicesArray
}

  return (
    <Container ref={el => container = el}>
      <TitleH1>Our Services</TitleH1>
      <ContentWrapper>
      {getServices(data)} 
      </ContentWrapper>
    </Container>
  )
}

export default Services

const Container = styled.div`
  visibility: hidden;
   height:max-content;
   padding: 4rem calc((100vw - 1300px) / 2);
    margin-bottom:2rem;
    position:relative;
  z-index:3;
  ${'' /* top:-50px; */}
  margin-top:-50px;

    @media screen and (max-width: 1100px){
      margin-top: -30px;
    }

    @media screen and (max-width: 983px){
      margin-top: 0;
    }
  
`
const TitleH1 = styled.h3`
display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  font-size: clamp(2rem, 5vw, 2rem);
  color: ${props => props.theme.colors.dark1};
  padding: 0 2rem;
  margin-bottom: 1rem;
  font-weight: 700;

 @media screen and (max-width: 768px){
     padding: 0 1rem;
     text-align:center;
 }
`

const ContentWrapper = styled.div`
  display:grid;
  grid-template-rows: 1fr 1fr;
  height:max-content;
`

const Section = styled.div`
 padding: 2rem 2rem;
 display:grid;
 grid-template-columns: repeat(2,1fr);
 grid-gap:10px;
   @media screen and (max-width: 900px){
       grid-template-columns: 1fr;
   }
`

const ColumnOne = styled.div`
  padding: 0 2rem;
 height:max-content;
 @media screen and (max-width: 768px){
     margin-bottom:2rem;
     padding: 0;
 }
`
const Paragraph = styled.div`
${'' /* padding-top: 1rem; */}
 padding-right:2rem;

 @media screen and (max-width: 768px){
     padding-right: 0 ;
 }
h3{
    font-size: clamp(1rem, 2.5vw, 1.3rem);
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.dark1};
  font-weight: 700;
     
  }
p{
    color: ${props => props.theme.colors.dark2};
  font-size: clamp(0.5rem, 5vw, 0.9rem);
  line-height:1.5;
}
`


const ColumnTwo = styled.div`
 height: max-content;
 padding: 0 2rem;
 @media screen and (max-width: 768px){
     padding: 0 ;
 }
`

const ImageContainer = styled.div`
overflow:hidden;
max-height:350px;
widht:100%;
border-radius: 10px;
`
const PurposeImg = styled(GatsbyImage)`
 width:100%;
 border-radius:10px;
 height:100%;
`

