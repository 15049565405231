import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/sections/services/hero/Hero"
import Services from "../components/sections/services/services/Services"
import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"

const ServicesPage = () => (
  <>
    <Seo title="Services" />
    <Hero/>
    <Services/>
    <NewsLetter/>
  </>
)

export default ServicesPage
