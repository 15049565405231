import React,{useRef,useEffect} from "react"
import styled from "styled-components"
import { Button } from "../../../reusableComponents/Button"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"


function Hero() {
  let container = useRef(null)
  let content = useRef(null)
  let tl = new gsap.timeline({ delay: 0.5 })

  useEffect(() => {
    const heroTitle = content.children[0]
    const button = heroTitle.nextSibling

    gsap.to(container, { duration: 0, css: { visibility: "visible" } })
    gsap.to(content, { duration: 0, css: { visibility: "visible" } })
    tl.from(
      [heroTitle.children , button],
      {
        duration: 1,
        opacity: 0,
        y: 100,
        ease: "power3.out",
        stagger: .15,
      },
    )
  }, [])
  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }
  return (
    <>
      <Wrap ref={el => (container = el)}>
        <HeroBg>
          <StaticImage
            style={imageStyle}
            src="../../../../assets/images/serviceBg.jpg"
            alt="Services Writers Way Solutions"
            placeholder="blurred"
            quality="100"
          />
        </HeroBg>
        <Content ref={el => (content = el)}>
        <ItemContainer>
          <Title>WE PUT YOU IN FRONT OF<br/> DECISION MAKERS</Title>
          </ItemContainer>
          {/* <Description>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere
            minima, modi <br />soluta adipisci labore quam.
          </Description> */}
          <ButtonContainer>
            <Button round="true" to="/contact">
              Contact us
            </Button>
          </ButtonContainer>
        </Content>
        <svg
          className="waveTwo"
          viewBox="0 0 1440 224"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64L48 74.7C96 85 192 107 288 96C384 85 480 43 576 26.7C672 11 768 21 864 42.7C960 64 1056 96 1152 90.7C1248 85 1344 43 1392 21.3L1440 0V224H1392C1344 224 1248 224 1152 224C1056 224 960 224 864 224C768 224 672 224 576 224C480 224 384 224 288 224C192 224 96 224 48 224H0V64Z"
            fill="hsl(209, 78%, 53%)"
            fillOpacity="1"
          />
        </svg>
        <svg
          className="wave"
          viewBox="0 0 1440 224"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 64L48 74.7C96 85 192 107 288 96C384 85 480 43 576 26.7C672 11 768 21 864 42.7C960 64 1056 96 1152 90.7C1248 85 1344 43 1392 21.3L1440 0V224H1392C1344 224 1248 224 1152 224C1056 224 960 224 864 224C768 224 672 224 576 224C480 224 384 224 288 224C192 224 96 224 48 224H0V64Z"
            fill="white"
          />
        </svg>
      </Wrap>
    </>
  )
}

export default Hero

const Wrap = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-top: -80px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, transparent 100%);
  }
`

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
// const Images = styled(StaticImage)`
//   width: 100%;
//   height: 100%;
//   -o-object-fit: cover;
//   object-fit: cover;
// `
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  max-height: 100%;
  width: 80%;
  max-width: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  ${"" /* font-weight: bold; */}
  overflow:hidden;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 300px) {
    width: 100%;
  }
  ${
    "" /* max-width:55%;
color: #fff; */
  }
  ${"" /* background:red; */}
`
export const ItemContainer = styled.div`
  width:100%;
  overflow:hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Title = styled.h1`
  font-size: clamp(1.5rem, 5vw, 4rem);
  ${"" /* padding: 0 1rem; */}
  margin-bottom:1.5rem;
  font-weight: 700;
  @media screen and (max-width: 250px) {
    padding: 0;
  }
  color: ${props => props.theme.colors.main3};
`
// const Description = styled.p`
//   font-size: clamp(0.7rem, 5vw, 0.9rem);
//   font-family: "Roboto", sans-serif;
//   margin-bottom: 1.5rem;
//   font-weight: 200;
//   max-width: 800px;
//   line-height: 1.5;
//   letter-spacing: 1px;
//   color: ${props => props.theme.colors.light2};
//   ${"" /* padding: 0 2rem; */}
//   @media screen and (max-width: 250px) {
//     padding: 0;
//   }
// `
const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
`
